<template>
	<section class="page--padding places"
			 :class="{'page--padding-with-banner':banner}">
		<v-container>
			<h1 class="mb-10 text-center">Где нас купить</h1>

			<v-row class="d-md-flex justify-md-center">
				<v-col cols="12">

					<div class="d-flex align-center places__cities">
						<div class="places__cities-border">
							<template v-for="(item,index) of cities">
                                <span class="item size2--u text-center" :class="{active:item.ID===form.cityId}"
									  @click="setCity(item)">{{ item.NAME }}</span>
								<span v-if="index+1<cities.length" class="dot"></span>
							</template>
						</div>
					</div>

				</v-col>
			</v-row>
		</v-container>

		<div class="places__block">
			<yandex-map :settings="mapSettings"
						:coords="map.center"
						:zoom="map.zoom"
						:controls="map.controls"
						:behaviors="map.behaviors"
						@map-was-initialized="onMapInited"
						class="places__map">
				<!--Markers-->

				<ymap-marker v-for="(item, index) of mapItems" :key="'point'+index"
							 :marker-id="'marker'+index"
							 :coords="item.coords"
							 :hint-content="item.hint"
							 :icon="item.icon"
							 @click="onMarkerClick">
					<places-balloon slot="balloon" :item="item"/>
				</ymap-marker>

			</yandex-map>

			<div class="places__list">
				<div class="places__frame">
					<v-expansion-panels flat accordion>
						<v-expansion-panel
								v-for="(section, index) of sections"
								:key="index"
						>
							<v-expansion-panel-header @click="onSectionClick(section)">
								<h4>{{ section.label }}</h4>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<ul class="ml-4">
									<li v-for="(place, i) of section.places"
										:key="i" :class="{active:selectedItem && selectedItem.ID===place.ID}"
										@click="onItemClick(place)">
										<h6>{{ place.NAME }}</h6>
										<div class="size2">{{ place.ADDRESS }}</div>
										<div class="size2">{{ place.SCHEDULE }}</div>
									</li>
								</ul>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</div>
		</div>

		<div class="section--padding text-center text-md-start ">
			<v-container class="container--narrow">
				<v-row class="align-center">
					<v-col cols="12" md="6" class="d-flex justify-center justify-md-start">
						<v-img src="@/assets/places/wheretobuy-img.jpeg" alt="Shop"
							   class="places__img mb-10 mb-md-0"
							   contain
						/>
					</v-col>
					<v-col cols="12" md="6" class="order-md-first d-flex justify-center justify-md-start">
						<div class="places__content">
							<h3 class="mb-10">Ждем вас за сладкими подарками для себя и близких</h3>
							<p class="limited">
								Конфеты Счастья так полюбились гостям, что за ними
								приходили специально, особенно в преддверии праздников и,
								именно это послужило импульсом для создания Шоколадной
								Фабрики СЧАСТЬЕ и ее уютных магазинов в Петербурге,
								Москве и Париже.
							</p>
							<p class="limited">
								Три первых вкуса пралине с течением времени
								сохранили свои цвета фантиков и рецептуру практически
								в первозданном виде и легли в основу базовой коллекции
								из 14 вкусов оригинальных пралине, которые по-прежнему
								дарят радость самым близким.
							</p>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</div>

	</section>
</template>

<script>
	import {yandexMap, ymapMarker, loadYmap} from 'vue-yandex-maps';

	import Config from '@/config';
	import {scrollTo} from '@/utils/utils';
	import Vue from "vue";
	import PlacesBalloon from "@/views/Places/places-balloon";


	const ICON_COLOR_DEFAULT = "rgb(32, 32, 32)";
	const ICON_COLOR_ACTIVE = "rgb(192, 156, 94)";
	const MAP_ITEM_ZOOM = 17;
	const MAP_ITEM_MIN_ZOOM = 15;
  const MAP_MAX_ZOOM = 18;
	const MAP_OFFSET_X_MANY = 0.05;
	const MAP_OFFSET_X_ONE = 0.002;

	let localYmaps;
	let map;


	export default {
		name: "places",
		components: {
			PlacesBalloon,
			yandexMap,
			ymapMarker,
		},
		data() {
			return {
				form: {
					countryId: null,
					cityId: null,
				},
				mapSettings: {
					apiKey: Config.YANDEX_MAP_API_KEY,
					lang: 'ru_RU',
					coordorder: 'latlong',
					enterprise: false,
					version: '2.1'
				},
				map: {
					center: [59.938955, 30.315644],
					zoom: 11,
					controls: ["zoomControl"],
					behaviors: ["dblClickZoom", "drag", "multiTouch"]
					//behaviors: []
				},
				items: null,
				mapItems: null,
				sections: null,
				selectedPlaces: null,
				selectedItem: null
			}
		},
		computed: {
			page() {
				//console.log(this.items)
				return this.$store.state.page?.PlacesController;
			},
			cities() {
				return this.page?.cities;
			},
			countries() {
				return this.page?.countries;
			},
			banner() {
				return this.$store.state.common.banner;
			},
		},
		methods: {
			setCity(item) {
				this.form.cityId = item.ID;
				this.items = this.initItems();
				this.mapItems = this.initItems();
				this.initSections();
				this.fitMap();
			},
			siteMask(item) {
				if (!item.site) return "";
				return item.site.replace(/^https?\:\/\/(.+)/, "$1")
			},
			onMapInited(mapRef) {
				map = mapRef;
			},
			onMapLoaded({map}) {
				this.fitMap();
			},
			onSectionClick(section) {
				this.mapItems = section.places;
				this.fitMap(section.places);

				//console.log("onSectionClick", section);
			},
			onItemClick(item) {
				this.selectedItem = item;
				this.map.zoom = MAP_ITEM_ZOOM;
				setTimeout(() => {
					let coords = [...item.coords];
					//coords[1] += MAP_OFFSET_X_ONE;
					this.map.center = coords;

					this.mapItems = [];
					setTimeout(() => {
						this.mapItems = this.initItems();
					}, 150);
				}, 300)
			},
			onMarkerClick(item) {
				//console.log("Marker", item);
			},
			applyMapOffset() {
				let coords = map.getCenter();
				let zoom = map.getZoom();
        if (zoom > MAP_MAX_ZOOM) {
          zoom = MAP_MAX_ZOOM;
        }
				//if (zoom >= MAP_ITEM_MIN_ZOOM) coords[1] += MAP_OFFSET_X_ONE;
				//else coords[1] += MAP_OFFSET_X_MANY;

				map.setCenter(coords, zoom);
			},
			fitMap(items) {
				items = items || this.items;
				if (!items) return;
				localYmaps?.geoQuery(items.map(el => new ymaps.Placemark(el.coords))).applyBoundsToMap(map);
				this.applyMapOffset();
			},
			initSections() {
				this.sections = this.page.sections.map(section => {
					return {
						label: section.NAME,
						places: this.items.filter(item => item.IBLOCK_SECTION_ID === section.ID)
					}
				});
			},
			initItems() {
				const items = this.page?.items?.filter(el => el.CITY_ID === this.form.cityId)
					.map(el => {
						//const phones = el.PHONE?.split(",").map(el => el.trim());
						//const emails = el.EMAIL?.split(",").map(el => el.trim());

						let item = {
							ID: el.ID,
							CITY_ID: el.CITY_ID,
							//logo: el.PREVIEW_PICTURE,
							NAME: el.NAME,
							ADDRESS: el.ADDRESS,
							PHONE: el.PHONE,
							SCHEDULE: el.SCHEDULE,
							//site: el.SITE,
							//tel: phones[0]?.replace(/\D/, ""),
							//emails,
							//phones,
							IBLOCK_SECTION_ID: el.IBLOCK_SECTION_ID,
							COLOR: ICON_COLOR_DEFAULT,
							icon: {
								layout: 'default#image',
								imageHref: this.selectedItem?.ID === el.ID ? '/static/pins/5.svg' : '/static/pins/6.svg',
								// imageSize: [42, 84],
								// imageOffset: [-21, 0],
								contentOffset: [0, 0],
								// contentLayout: '<div class="places__placemark-content"><img src="/static/pins/6.svg"><span class="d-none d-md-block" style="width:320px">$[properties.iconContent]</span></div>'
							}
						};
						const geo = el.GEO?.split(",");
						if (geo.length >= 2) {
							const lng = parseFloat(geo[0]);
							const lat = parseFloat(geo[1]);
							item.coords = [lat, lng];
						}
						return item;
					});

				items.sort((a, b) => {
					const ca = this.cities.find(el => el.ID === a.CITY_ID);
					const cb = this.cities.find(el => el.ID === b.CITY_ID);
					if (ca.SORT < cb.SORT) return -1;
					if (ca.SORT > cb.SORT) return 1;
					if (ca.NAME < cb.NAME) return -1;
					if (ca.NAME > cb.NAME) return 1;
					return 0;
				});

				return items;
			},
		},
		mounted() {
			loadYmap().then(() => {
				localYmaps = ymaps;
			});

			this.$store.state.navpath = [
				{
					label: "Магазины"
				},
			];

			this.initSections();
		},
		created() {
			// preselect Russia, SPb
			this.form.countryId = this.countries[0].ID;
			this.form.cityId = this.cities[0].ID;

			this.items = this.initItems();
			this.mapItems = this.initItems();
		}
	}
</script>

<style lang="scss">
	.places {

		&__block {
			position: relative;
			height: 100%;
			margin-top: 8px;

			@include up($md) {
				display: flex;
				height: 60vh;
			}

			.container {
				padding: 0;
				height: 0;
				display: flex;
				justify-content: flex-end;
			}
		}

		&__map {
			height: 40vh;
			width: 100%;

			@include up($md) {
				width: 50%;
				height: 100%;
			}
		}

		&__list {
			//margin-top: 32px;
			position: relative;
			z-index: 1;
			width: 100%;
			//max-width: 640px;
			//height: calc(60vh - 32px - 32px);
			background: $bg;
			padding: 80px 60px;

			@include up($md) {
				width: 50%;
			}
		}

		&__frame {
			height: 100%;
			//max-width: 480px;
			overflow-y: auto;

			@include up($md) {
				max-width: 480px;
			}

			&::-webkit-scrollbar {
				width: 2px;
			}

			&::-webkit-scrollbar-track {
				background: rgba(0, 0, 0, 0.05);
				//-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
				//border-radius: 10px;
			}

			&::-webkit-scrollbar-thumb {
				//border-radius: 10px;
				//-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
				background: rgba(0, 0, 0, 0.2);
			}

			.theme--light.v-expansion-panels .v-expansion-panel {
				background: transparent;
			}

			li {
				cursor: pointer;
				@include transition();

				&:not(:first-child) {
					margin-top: 16px !important;
					padding-top: 16px !important;
					border-top: 1px solid $gray-light;
				}

				&:hover,
				&:active {
					color: $golden;
				}

				&.active {
					color: $black;
				}
			}

			.v-expansion-panels {
				max-width: 480px;
				margin: 0 auto;
			}

			.v-expansion-panel {
				&:not(:first-child) {
					border-top: 1px solid $gray-light;
				}
			}

			.v-expansion-panel-header {
				//padding-left: 0 !important;
				//padding-right: 0 !important;

				cursor: pointer;
				@include transition();


				&:hover,
				&:active {
					color: $golden;
				}
			}
		}

		&__item {
			padding: 48px;
			//margin-bottom: 40px;
			border: 1px solid $primary-100;
			height: 100%;
			cursor: pointer;
			@include transition();

			&:hover {
				border: 1px solid $golden;
			}

			.logo {
				width: 48px;
				height: 48px;
				margin: 0 0 24px 0;
			}

			.block {
				border-top: 1px solid $golden;
				padding-top: 20px;
				margin-top: 20px;
				display: flex;
				flex-direction: column;
			}

			.site, .phone, .email {
				outline: none;
				@include transition();

				&:hover {
					//color: $black-2 !important;
				}
			}

		}

		&__cities {
			display: flex;
			justify-content: center;

			.item {
				@include transition();
				cursor: pointer;

				&:hover {
					color: $golden;
				}

				&.active {
					color: $black !important;
					//font-weight: 300;
				}
			}

			&-border {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0 32px 14px 32px;
				border-bottom: 1px solid $primary-100;
			}
		}

		&__content {
			max-width: 460px;
		}

		&__img {
			max-height: 660px;
		}
	}
</style>
